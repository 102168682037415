html {
  height: 100%;
  box-sizing: border-box;
}

*,*:before,*:after {box-sizing: inherit;}

body {
  min-width: 320px;
  font-family: @main-font;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.page {
  min-height: 100%;
}

.main-content {
  flex-grow: 1;
}

/**
* Основной контейнер
*/
.container {
  width: 100%;
  max-width: 1220px;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 980px;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}

/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button { cursor: pointer; }
address { font-style:normal; }
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content {
  & .mfp-close {

    opacity: 1;

    & .icon {
      width: 25px;
      height: 25px;
      fill: @primary-color;
    }
  }
}

/**
* это иконки из svg-sprite
* указываем дефолтную ширину, высоту(для ие10)
*/
.icon {
  display: inline-block;
  width: 70px;
  height: 70px;
}

/**
* footer webmotor
*/
.webmotor {
  & span {
    color: #fff;
  }
  & a {
    color: #fff;
    text-decoration: underline;
    transition: 0.15s color ease-in;
  }
}

.page-head {
  margin-bottom: 20px;
}
