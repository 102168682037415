/**
* webmotor
*/
/* variables */
/**
* 3. ШРИФТЫ
* -----------------------------------------------------------------------------
*/
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
/**
* 4. ТИПОГРАФИКА
* -----------------------------------------------------------------------------
*/
/**
* 5. КОМПОНЕНТЫ (кнопки, хлебные крошки, пагинация, js-компоненты (слайдер, спойлер))
* -----------------------------------------------------------------------------
*/
/**
* общие стили сайта, сюда входит:
* 1. стили раскладки страницы (sticky footer)
* 2. настройки контейнеров
* 3. и разные общие стили
*/
html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  min-width: 320px;
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
.page {
  min-height: 100%;
}
.main-content {
  flex-grow: 1;
}
/**
* Основной контейнер
*/
.container {
  width: 100%;
  max-width: 1220px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 980px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
}
address {
  font-style: normal;
}
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content .mfp-close {
  opacity: 1;
}
.mfp-content .mfp-close .icon {
  width: 25px;
  height: 25px;
  fill: #00384e;
}
/**
* это иконки из svg-sprite
* указываем дефолтную ширину, высоту(для ие10)
*/
.icon {
  display: inline-block;
  width: 70px;
  height: 70px;
}
/**
* footer webmotor
*/
.webmotor span {
  color: #fff;
}
.webmotor a {
  color: #fff;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}
.page-head {
  margin-bottom: 20px;
}
/**
* Это основной стилевой файл
*/
html,
button,
input,
select,
textarea {
  font-family: 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #373737;
}
body {
  font-size: 16px;
  line-height: 1.5em;
  background: #fff;
  height: 100%;
  min-height: 100%;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
/* Links */
a:focus {
  outline: 0;
}
a:hover,
a:active {
  outline: 0;
}
/* Embedded content */
img {
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
/* Lists */
ul {
  list-style: none;
}
/* Forms */
legend {
  white-space: normal;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}
button,
input {
  line-height: normal;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
/* Tables */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: top;
}
/* Typography */
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn,
em,
i {
  font-family: Georgia, "Bitstream Charter", "Century Schoolbook L", "Liberation Serif", Times, serif;
  font-style: italic;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1.5em 0;
  padding: 0;
}
pre,
code,
kbd,
samp {
  font-family: "Courier New", Courier, FreeMono, "Nimbus Mono L", "Liberation Mono", monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
span {
  margin-right: 2px;
}
.ui-carousel {
  height: 100px;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  /*for ie7 to work e.g in html carousels*/
  width: 300px;
}
.ui-carousel > .wrapper {
  margin: 0;
  padding: 0;
  width: 9999px;
}
.ui-carousel > .wrapper > * {
  border: 0;
  display: block;
  float: left;
  height: 100px;
  overflow: hidden;
  width: 100px;
}
.page {
  width: 1280px;
  margin: 0 auto;
  background-color: #f4f4f4;
  padding-bottom: 265px;
  position: relative;
  box-sizing: border-box;
}
.link {
  color: #0082dd;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
.h1 {
  font-size: 38px;
  line-height: 1;
}
.h2 {
  font-size: 28px;
  line-height: 1.2;
}
.h3 {
  font-size: 24px;
  line-height: 1.2;
}
.absolute-block-link {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.accent-text {
  color: #0082dd;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.page-button {
  border-radius: 3px;
  padding: 10px 17px;
  cursor: pointer;
}
.page-button:hover,
.page-button_light:hover {
  box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.3);
}
.page-button:focus,
.page-button_light:focus {
  outline: none;
  box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.3);
}
.page-button_dark {
  border-radius: 3px;
  outline: none;
  color: #fff;
  background-color: #e54d30;
  border: none;
  text-decoration: none;
  cursor: pointer;
}
.page-button_dark:hover {
  background-color: #e54d30;
  color: #fff;
}
.page-button_light {
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: transparent;
  color: #e54d30;
  text-decoration: none;
  border: 2px solid #e54d30;
  padding: 8px 15px;
}
.page-button_light:hover {
  background-color: #e54d30;
  color: #fff;
}
.page-content {
  padding-left: 80px;
  padding-right: 80px;
}
.buttons-wrp {
  margin-top: 15px;
}
.buttons-wrp button {
  margin-right: 6px;
}
.buttons-wrp .link {
  margin-left: 5px;
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "bold";
  font-style: "normal";
  src: url("_font_arial/b-rub_font_arial_type_bold.eot");
  src: url("_font_arial/b-rub_font_arial_type_bold.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_bold.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_bold.ttf") format('truetype');
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "normal";
  font-style: "italic";
  src: url("_font_arial/b-rub_font_arial_type_italic.eot");
  src: url("_font_arial/b-rub_font_arial_type_italic.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_italic.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_italic.ttf") format('truetype');
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "bold";
  font-style: "italic";
  src: url("_font_arial/b-rub_font_arial_type_bolditalic.eot");
  src: url("_font_arial/b-rub_font_arial_type_bolditalic.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_bolditalic.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_bolditalic.ttf") format('truetype');
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "normal";
  font-style: "normal";
  src: url("_font_arial/b-rub_font_arial_type_regular.eot");
  src: url("_font_arial/b-rub_font_arial_type_regular.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_regular.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_regular.ttf") format('truetype');
}
.b-rub_font_arial {
  font-family: 'b-rub_font_arial';
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "bold";
  font-style: "normal";
  src: url("_font_arial/b-rub_font_arial_type_bold.eot");
  src: url("_font_arial/b-rub_font_arial_type_bold.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_bold.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_bold.ttf") format('truetype');
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "normal";
  font-style: "italic";
  src: url("_font_arial/b-rub_font_arial_type_italic.eot");
  src: url("_font_arial/b-rub_font_arial_type_italic.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_italic.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_italic.ttf") format('truetype');
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "bold";
  font-style: "italic";
  src: url("_font_arial/b-rub_font_arial_type_bolditalic.eot");
  src: url("_font_arial/b-rub_font_arial_type_bolditalic.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_bolditalic.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_bolditalic.ttf") format('truetype');
}
@font-face {
  font-family: 'b-rub_font_arial';
  font-weight: "normal";
  font-style: "normal";
  src: url("_font_arial/b-rub_font_arial_type_regular.eot");
  src: url("_font_arial/b-rub_font_arial_type_regular.eot?#iefix") format('embedded-opentype'), url("_font_arial/b-rub_font_arial_type_regular.woff") format('woff'), url("_font_arial/b-rub_font_arial_type_regular.ttf") format('truetype');
}
.b-rub_font_arial {
  font-family: 'b-rub_font_arial';
}
/* stylus config */
/* text styles */
.b-text p,
.b-text-p {
  margin-bottom: 12px;
  font-family: 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.b-text h1,
.b-text-h1,
.b-text h2,
.b-text-h2,
.b-text h3,
.b-text-h3,
.b-text h4,
.b-text-h4,
.b-text h5,
.b-text-h5,
.b-text h6,
.b-text-h6 {
  text-rendering: optimizelegibility;
  margin-top: 24px;
  margin-bottom: 12px;
}
.b-text h1 a,
.b-text-h1 a,
.b-text h2 a,
.b-text-h2 a,
.b-text h3 a,
.b-text-h3 a,
.b-text h4 a,
.b-text-h4 a,
.b-text h5 a,
.b-text-h5 a,
.b-text h6 a,
.b-text-h6 a {
  color: inherit;
  text-decoration: none;
}
.b-text h1:first-child,
.b-text-h1:first-child,
.b-text h2:first-child,
.b-text-h2:first-child,
.b-text h3:first-child,
.b-text-h3:first-child,
.b-text h4:first-child,
.b-text-h4:first-child,
.b-text h5:first-child,
.b-text-h5:first-child,
.b-text h6:first-child,
.b-text-h6:first-child {
  margin-top: 0;
}
.b-text h1,
.b-text-h1 {
  font: normal 48px/70px 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #373737;
  border-bottom: 2px solid #000;
  margin-bottom: 24px;
}
.b-text h2,
.b-text-h2 {
  font: bold 36px/36px 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #373737;
}
.b-text h3,
.b-text-h3 {
  font: normal 24px/24px 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #c00;
}
.b-text h4,
.b-text-h4 {
  font: bold 18px/24px 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #373737;
}
.b-text h5,
.b-text-h5 {
  font: normal 16px/24px 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #373737;
}
.b-text h6,
.b-text-h6 {
  font: normal 16px/24px 'PT Sans Caption', Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
  color: #373737;
}
.b-text ul,
.b-text ol {
  padding: 0;
  margin: 0 0 12px 36px;
}
.b-text ul ul,
.b-text ul ol,
.b-text ol ol,
.b-text ol ul {
  margin-bottom: 0;
}
.b-text ul {
  list-style: none;
}
.b-text ul > li:before {
  content: '\2014\a0';
  float: left;
  margin-left: -1.25em;
}
.b-text ol {
  list-style: decimal;
}
.b-text li {
  line-height: 24px;
}
.b-text dl {
  margin-bottom: 12px;
}
.b-text dt,
.b-text dd {
  line-height: 24px;
}
.b-text dt {
  font-weight: bold;
  line-height: 23px;
}
.b-text dd {
  margin-left: 24px;
}
.b-text abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
}
.b-text pre {
  padding: 12px 24px;
  margin-bottom: 12px;
  border-left: 2px solid #c00;
  background: #eee;
  font-size: 14px;
}
.b-text blockquote {
  padding: 24px;
  margin-bottom: 12px;
  border-left: 5px solid #000;
}
.b-text small {
  font-size: 0.85em;
}
.b-text table {
  margin-bottom: 12px;
}
.b-text th,
.b-text td {
  font-size: 13px;
  padding: 0 5px;
  border: 1px solid #ccc;
}
.b-text th {
  font-weight: bold;
  background: #eee;
  text-align: left;
}
.b-text address {
  display: block;
  margin-bottom: 12px;
  line-height: 24px;
}
.breadcrumbs {
  margin: 0 80px 20px;
}
.breadcrumbs__item {
  display: inline-block;
  font-size: 14px;
}
.breadcrumbs__item:last-child a {
  color: #373737;
  text-decoration: none;
}
.breadcrumbs__item::before {
  content: '/';
  color: #0082dd;
  margin-right: 5px;
}
.breadcrumbs__item::after {
  content: '';
  margin-left: 5px;
}
.breadcrumbs__item:first-child::before {
  display: none;
}
.carousel {
  position: relative;
}
.carousel-points {
  font-family: Arial, Helvetica, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif;
}
.carousel-points__item {
  display: inline-block;
  color: #d6d6d6;
  font-size: 24px;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 1;
}
.carousel-points__item a {
  color: #d6d6d6;
  text-decoration: none;
}
.carousel-points__item a:hover {
  color: #0082dd;
  text-decoration: none;
}
.carousel-points__item_active {
  color: #0082dd;
  text-decoration: none;
}
.carousel-points_infobanner {
  position: absolute;
  left: 40px;
  bottom: 10px;
}
.carousel-points_review {
  text-align: center;
  margin-top: 30px;
}
.carousel__arrows {
  position: absolute;
  background: url("../img/carousel__arrows.png");
  width: 30px;
  height: 55px;
  cursor: pointer;
}
.carousel__arrows_left {
  background-position: 0 0;
  left: -50px;
  top: 50%;
  margin-top: -28px;
}
.carousel__arrows_left:hover {
  background-position: 0 -56px;
}
.carousel__arrows_right {
  background-position: -31px 0;
  right: -50px;
  top: 50%;
  margin-top: -28px;
}
.carousel__arrows_right:hover {
  background-position: -31px -56px;
}
.carousel__arrows_left_review,
.carousel__arrows_right_review {
  margin-top: 0;
  top: 113px;
}
/*.carousel-wrp
	position: relative;
	overflow: hidden;
	width 1120px
.carousel-content-list
	width: 20000em;
	position: relative;*/
.carousel-content-list__item {
  width: 1116px;
  height: 308px;
  position: relative;
  padding: 40px;
  box-sizing: border-box;
}
.contact-page__colon {
  display: inline-block;
  vertical-align: top;
  width: 49%;
  margin-top: 5px;
}
.contact-page__map-wrp {
  margin: 30px 0 25px;
  height: 430px;
}
.form__block {
  margin-top: 25px;
}
.form {
  width: 615px;
  margin-top: 20px;
  font-size: 14px;
}
.form__field-wrp {
  margin-right: 20px;
  width: 284px;
  margin-top: 15px;
}
.form__trxtareawrp {
  margin-right: 20px;
  margin-top: 15px;
}
.form__checkbox-wrp {
  width: 400px;
  margin-top: 30px;
}
.form__field-wrp_inline {
  display: inline-block;
  vertical-align: top;
}
.jq-selectbox {
  display: block;
  width: 100%;
}
.jq-selectbox.dropdown .jq-selectbox__trigger,
.jq-selectbox.opened .jq-selectbox__trigger {
  background-position: -19px 0;
}
.jq-selectbox__select {
  width: 100%;
  padding: 6px 25px 5px 10px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  display: block;
}
.jq-selectbox__select:hover .jq-selectbox__trigger {
  background-position: -19px 0;
}
.jq-selectbox__select-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jq-selectbox__trigger {
  display: block;
  position: absolute;
  right: 2px;
  top: 2px;
  height: 31px;
  width: 19px;
  background: url("../img/arrow.png") 0 0 no-repeat;
}
.jq-selectbox__dropdown {
  background-color: #fff;
  box-sizing: border-box;
  padding: 3px 0;
  line-height: 1.2;
  border: 1px solid #d6d6d6;
}
.jq-selectbox__dropdown ul {
  max-height: 408px;
}
.jq-selectbox__dropdown li {
  padding: 7px 11px;
  cursor: pointer;
}
.jq-selectbox__dropdown li:hover {
  text-decoration: underline;
}
.sel {
  background-color: #f4f4f4;
}
.form__label {
  display: block;
  font-size: 14px;
}
.form__select {
  height: 50px;
  width: 280px;
}
.form__text {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  background-color: #fff;
  display: block;
  box-sizing: border-box;
  margin-top: 3px;
}
.form__textarea {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  background-color: #fff;
  display: block;
  box-sizing: border-box;
  margin-top: 3px;
  height: 150px;
}
.form__checkbox-label {
  display: inline;
  vertical-align: baseline;
}
.form__checkbox {
  margin-right: 5px;
}
.form__file-wrp {
  margin-right: 20px;
  width: 190px;
  text-align: right;
  margin-top: 30px;
}
.form__file {
  opacity: 0;
  position: absolute;
  left: -99999px;
}
.form__file-label {
  color: #0082dd;
  cursor: pointer;
}
.form__file-clip {
  height: 11px;
  margin-right: 7px;
  vertical-align: baseline;
}
.form__button-wrp {
  margin-top: 30px;
}
.form__error {
  color: #e54d30;
  font-size: 12px;
  line-height: 22px;
  margin-top: 5px;
}
.benefits {
  padding: 80px;
}
.benefits__title {
  margin-bottom: 35px;
  text-align: center;
}
.benefits__item {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 265px;
  height: 220px;
  margin: 0 15px 20px 0;
  padding: 45px 30px 30px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #0082dd;
  border-radius: 4px;
}
.benefits__item:last-child {
  margin-right: 0;
}
.benefits__number {
  color: #0082dd;
  font-size: 50px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 10px;
}
.benefits__description {
  line-height: 1.2;
}
.h2_index {
  font-size: 36px;
  line-height: 1;
}
.information-block {
  background-color: #fff;
  padding: 80px;
}
.information-block__item {
  display: inline-block;
  vertical-align: top;
  width: 32.5%;
  box-sizing: border-box;
  padding: 0 30px 0 0;
}
.information-block__item:last-child {
  padding: 0;
}
.information-block__title {
  margin-bottom: 10px;
}
.information-block__description {
  margin-bottom: 10px;
}
.information-block__description-item {
  line-height: 1.2;
  margin-bottom: 8px;
}
.information-banner-wrp {
  background-color: #fff;
  padding: 80px;
  overflow: hidden;
  position: relative;
}
.information-banner {
  border: 2px solid #0082dd;
  border-radius: 4px;
  position: relative;
  height: 310px;
  box-sizing: border-box;
}
.information-banner__text {
  max-width: 575px;
}
.information-banner__link {
  text-decoration: none;
}
.information-banner__link:hover {
  text-decoration: underline;
}
.information-banner__description {
  margin: 5px 0 15px;
  color: #000;
}
.information-banner__picture {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.information-banner__picture_one {
  bottom: -2px;
}
.map-block {
  padding: 0 80px;
}
.map-block__title {
  text-align: center;
  margin-bottom: 40px;
}
.map-block__image {
  width: 100%;
}
.review {
  padding: 0 80px 57px;
}
.review__title {
  text-align: center;
  margin-bottom: 30px;
}
.review__item {
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 0 0;
  background-color: #fff;
  width: 550px;
  height: 270px;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}
.review__item:nth-child(2n) {
  margin: 0;
}
.review__text {
  font-family: Georgia, "Bitstream Charter", "Century Schoolbook L", "Liberation Serif", Times, serif;
  font-style: italic;
  margin-bottom: 10px;
}
.review__author {
  position: absolute;
  bottom: 15px;
  left: 30px;
  right: 30px;
  min-height: 83px;
  line-height: 1.2;
}
.review__avatar {
  float: left;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-size: cover;
  margin: 0 15px 15px 0;
}
.review__name {
  font-weight: bold;
  margin-top: 7px;
}
.seo-text {
  padding: 80px;
}
.seo-text__title {
  text-align: center;
  margin-bottom: 30px;
}
.seo-text__person {
  width: 270px;
  padding: 10px 25px 0 30px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  box-sizing: border-box;
}
.seo-text__avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover;
  margin-bottom: 20px;
}
.seo-text__name {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 4px;
}
.seo-text__person-post {
  color: #7f7f7f;
  font-size: 14px;
  line-height: 1.3;
}
.seo-text__text-block {
  display: inline-block;
  vertical-align: top;
  width: 835px;
  min-height: 145px;
  padding: 35px 40px;
  font-style: italic;
  font-size: 18px;
  font-family: Georgia, "Bitstream Charter", "Century Schoolbook L", "Liberation Serif", Times, serif;
  line-height: 1.4;
  border: 2px solid #0082dd;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  margin-left: 10px;
}
.seo-text__quote-arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  left: -27px;
  top: 103px;
  background: url("../img/seo-text__quotes-arrow.png") 0 0 no-repeat;
}
.services-cards {
  padding: 0 80px 50px;
}
.services-cards__item {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 265px;
  height: 350px;
  margin: 0 15px 20px 0;
  padding: 25px 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.services-cards__item:last-child {
  margin-right: 0;
}
.services-cards__image {
  position: absolute;
  bottom: 35px;
  left: 50%;
  width: auto;
  height: auto;
  background: 50% 100% no-repeat;
  background-size: contain;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.services-cards__image_pic_one {
  width: 183px;
  background-image: url("../img/services-cards__image1.png");
  margin-left: -91px;
}
.services-cards__image_pic_two {
  width: 136px;
  background-image: url("../img/services-cards__image2.png");
  margin-left: -68px;
}
.services-cards__image_pic_three {
  width: 146px;
  background-image: url("../img/services-cards__image3.png");
  margin-left: -73px;
  height: 192px;
}
.services-cards__image_pic_four {
  width: 148px;
  background-image: url("../img/services-cards__image4.png");
  margin-left: -74px;
  bottom: 33px;
}
.services-cards__title {
  text-transform: uppercase;
  font-size: 18px;
}
.services-cards__item:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}
.services-cards__item:hover .services-cards__title {
  color: #0082dd;
}
.services-cards__buttons-wrp {
  text-align: center;
}
.horiz-menu {
  display: inline-block;
  max-width: 968px;
}
.horiz-menu__item {
  display: inline-block;
  padding-right: 37px;
  vertical-align: baseline;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.horiz-menu__item_active,
.horiz-menu__link_active {
  color: #b7b7b7;
  cursor: default;
}
.horiz-menu__link {
  color: #373737;
  text-decoration: none;
}
.horiz-menu__link:hover {
  text-decoration: underline;
  color: #0082dd;
}
.horiz-menu_header {
  margin-top: 34px;
}
.horiz-menu__link_active:hover {
  color: #b7b7b7;
  text-decoration: none;
}
.horiz-menu__item a.active {
  color: #b7b7b7;
}
.outlinks {
  width: 100%;
  font-size: 0;
}
.outlinks__item {
  display: inline-block;
  box-sizing: border-box;
  width: 426px;
  padding: 14px 20px;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  font-size: 16px;
  text-align: center;
}
.outlinks__item:first-child {
  text-align: left;
  padding-left: 80px;
  border-left: none;
  width: 427px;
}
.outlinks__item:last-child {
  text-align: right;
  padding-right: 80px;
  border-right: none;
  width: 427px;
}
.outlinks__text {
  color: #373737;
  text-decoration: none;
}
.outlinks__text:hover {
  text-decoration: underline;
  color: #0082dd;
}
.outlinks__text_active {
  color: #0082dd;
  cursor: default;
}
.outlinks__text_active:hover {
  text-decoration: none;
}
.page-footer {
  width: 100%;
  padding: 0 80px 50px;
  position: absolute;
  bottom: 0;
}
.page-button_footer {
  float: right;
  padding: 10px 13px;
  margin-top: -8px;
}
.page-footer__logo {
  color: #017bd0;
  font-size: 32px;
  letter-spacing: 0.025em;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 15px;
}
.page-footer__contact-blocks {
  display: inline-block;
  vertical-align: top;
  color: #666;
  margin-right: 45px;
}
.page-footer_inner {
  padding-top: 80px;
}
.page-header {
  padding: 60px 80px 50px;
}
.page-header__logo-wrp {
  position: relative;
  display: inline-block;
  margin-top: -43px;
}
.page-header__logo-text {
  display: inline-block;
  color: #017bd0;
  font-size: 55px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.05em;
  margin-right: 10px;
  position: relative;
  top: 0;
}
.page-header__logo {
  vertical-align: baseline;
}
.search {
  display: inline-block;
  float: right;
  position: relative;
  padding-left: 20px;
}
.search__input {
  padding: 12px 30px 8px 10px;
  font-size: 13px;
  width: 286px;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
}
.search__magnifier {
  position: absolute;
  top: 16px;
  display: inline-block;
  width: 13px;
  height: 13px;
  right: 12px;
  background: url("../img/search__magnifier.png") 0 0 no-repeat, transparent;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
}
.search__magnifier:hover {
  background-position: -13px 0;
}
.phone {
  float: right;
  width: 265px;
}
.phone__number {
  color: #000;
  font-size: 20px;
  line-height: 1;
  padding-top: 1px;
}
.phone__callback {
  font-size: 13px;
  cursor: pointer;
  padding-left: 11px;
  letter-spacing: 0.02em;
  text-decoration: underline;
}
.phone__callback:hover {
  color: #0082dd;
}
.page-button_header {
  float: right;
  margin-top: 25px;
  padding: 10px 13px;
}
.page-header_inner {
  padding-bottom: 30px;
}
.popup-wrp {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 10000;
  display: none;
}
.popup {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #f4f4f4;
  padding: 25px;
  border-radius: 2px;
}
.thankyou-popup {
  margin-top: -150px;
  margin-left: -225px;
}
.callback-popup {
  padding: 35px 40px;
  margin-top: -170px;
  margin-left: -183px;
}
.ask-popup {
  padding: 35px 14px 35px 35px;
  margin-top: -255px;
  margin-left: -331px;
}
.cart-popup {
  padding: 25px 20px 25px 20px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 23, 33, 0.15);
  width: 930px;
  top: 30px;
}
.service-popup {
  padding: 35px 14px 35px 35px;
  margin-top: -315px;
  margin-left: -332px;
}
.popup__cross {
  position: absolute;
  opacity: 0.75;
  right: 10px;
  top: 12px;
  width: 15px;
  height: 15px;
  background: url("../img/popup-cross.png") 0 0 no-repeat;
  cursor: pointer;
}
.popup__cross:hover {
  opacity: 1;
}
.thankyou-content {
  display: none;
  padding: 95px 0;
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
}
.thankyou-content__title {
  color: #e54d30;
}
.callback-content {
  display: none;
}
.callback-form {
  margin-top: 30px;
  width: 285px;
  font-size: 14px;
}
.callback-form__field-wrp {
  margin-bottom: 15px;
}
.callback-form__button-wrp {
  margin-top: 22px;
}
.ask-content {
  display: none;
}
.ask-form {
  width: 614px;
  font-size: 14px;
}
.ask-form__button-wrp {
  margin-top: 20px;
}
.service-content {
  display: none;
}
.cart-content {
  display: none;
}
.services-description__block {
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 30px 0;
  min-height: 80px;
}
.services-description__icon-wrp {
  display: inline-block;
  vertical-align: top;
  float: left;
}
.services-description__icon {
  width: 62px;
  margin-right: 15px;
  height: 80px;
  background-image: url("../img/services__icons.png");
  background-repeat: no-repeat;
}
.services-description__icon_one {
  background-position: 0 0;
  width: 65px;
}
.services-description__icon_two {
  background-position: -65px 0;
  width: 59px;
  margin-left: 2px;
}
.services-description__icon_three {
  background-position: -124px 0;
}
.services-description__icon_four {
  background-position: -186px 0;
}
.services-description__text-block {
  padding-left: 85px;
}
.services-description__title {
  margin-bottom: 10px;
}
.services-description__link {
  display: block;
}
.services-description__links-list {
  margin: 5px 0;
}
.services-description__text {
  margin-bottom: 25px;
  line-height: 22px;
}
.services-description__text-block_no-icon {
  padding-left: 0;
}
.services-cards-wrp_inner-page {
  margin: 20px 0 30px;
}
.services-cards__item_inner-page {
  width: 190px;
  height: 260px;
  padding: 15px;
}
.services-cards__item_inner-page .services-cards__title {
  font-size: 14px;
  line-height: 19px;
  display: inline-block;
}
.services-cards__item_inner-page .services-cards__image {
  bottom: 15px;
  max-height: 140px;
  max-width: 150px;
}
.sidebar {
  width: 265px;
  float: right;
}
.sidebar__menu-wrp {
  padding: 20px 0 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.sidebar__menu-title {
  padding: 10px 20px;
}
.sidebar__menu-item_active {
  background-color: #f4f4f4;
}
.sidebar__menu {
  padding: 5px 0;
}
.sidebar__menu-item {
  margin-bottom: 6px;
  line-height: 1.25;
  padding: 5px 40px;
}
.sidebar__button-wrp {
  margin: 30px 0 30px 40px;
}
.sidebar__button-descript {
  font-size: 18px;
  margin-bottom: 5px;
}
.sidebar__submenu {
  margin: 10px -40px -11px;
  background-color: #fff;
  font-size: 14px;
}
.sidebar__submenu .sidebar__menu-item {
  margin-bottom: 0;
  padding-left: 60px;
}
.text-page-content {
  padding-right: 300px;
}
.page-content__title {
  margin: 10px 0 0;
}
.text p {
  margin: 20px 0;
  line-height: 1.2;
}
.text h1 {
  margin: 25px 0;
  font-size: 36px;
  line-height: 1.1;
}
.text h2 {
  margin: 25px 0;
  font-size: 28px;
  line-height: 1.15;
}
.text h3 {
  margin: 25px 0;
  font-size: 24px;
  line-height: 1.2;
}
.text ol {
  margin: 10px 0 10px 25px;
}
.text li {
  margin-bottom: 10px;
}
.text ul {
  list-style: disc;
  margin: 10px 0 10px 20px;
}
.text a {
  color: #0082dd;
  text-decoration: none;
}
.text a:hover {
  text-decoration: underline;
}
.text table {
  margin: 30px 0;
  font-size: 16px;
}
.text th {
  font-weight: bold;
  border-bottom: 2px solid #d3d3d3;
  background-color: #fff;
  vertical-align: bottom;
  text-align: left;
}
.text th,
.text td {
  padding: 10px 20px;
  border-right: 2px solid #d3d3d3;
}
.text th:last-child,
.text td:last-child {
  border-right: none;
}
.text tr:nth-child(2n) td {
  background-color: #fff;
}
.text .left {
  float: left;
  margin: 5px 20px 10px 0;
}
.text .right {
  float: right;
  margin: 5px 0 20px 10px;
}
.text-page-content h1 {
  margin-bottom: 20px;
}
.text-page-content h3 {
  margin: 0;
}
.layer h3 {
  margin-bottom: 2px;
}
.layer p {
  font-size: 14px;
  margin-top: 1px;
}
.borders-text {
  margin: 30px 0;
  padding: 20px 40px 30px 25px;
  border-radius: 3px;
  border: 2px solid #0082dd;
}
.borders-text h1,
.borders-text h2 {
  margin-top: 0;
}
.borders-text.borders-text_first-block {
  margin-top: 0;
}
p.link-list {
  margin: 6px 0;
}
.attachment {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.2;
}
.attachment p {
  margin: 5px;
}
.attachment .attachment-type {
  color: #7f7f7f;
  margin-bottom: 10px;
}
.attachment .attachment-link {
  color: #0082dd;
  text-decoration: none;
  cursor: pointer;
}
.attachment .attachment-link:hover {
  text-decoration: none;
}
.attachment .attachment-link:hover .attachment-link-text {
  text-decoration: underline;
}
.attachment .attachment-link-icon {
  background: url("../img/attachment-upload-icon.png") 0 0 no-repeat;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 5px;
}
.bigger {
  font-size: 20px;
}
/**
* input type file (bootstrap file input plugin)
*/
.file-input-wrapper span {
  display: block;
  color: #0082dd;
  cursor: pointer;
}
.file-input-wrapper span::before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  background: url(../img/clip.png) center no-repeat;
  background-size: 100%;
  /*background: url(/bitrix/templates/main/publish/clip.png) center no-repeat;*/
}
.file-input-name {
  display: block;
}
.form__field--require::after {
  content: '*';
  padding-left: 5px;
  font-size: 16px;
  color: red;
}
.form-callback__file-checkbox {
  position: relative;
  z-index: 1;
  float: left;
}
.form-callback__file-input {
  display: block;
  width: auto;
}
.form-callback__file-input .input-type-file {
  display: block;
  z-index: 0;
  margin-bottom: 10px;
}
.file-input-name {
  white-space: nowrap;
  overflow: hidden;
}
/**
* search result page
* -----------------------------------------------------------------------------
*/
.form-result-section-item {
  display: flex;
  align-items: baseline;
}
.form-result-section__link {
  margin-right: 5px;
  color: #0082dd;
  text-decoration: none;
}
.form-result-section__link:hover {
  text-decoration: underline;
}
.form-result-item-wrapper {
  margin-bottom: 25px;
}
.search-page-form {
  margin-bottom: 55px;
  display: flex;
}
.search-page-form__input {
  width: 360px;
  height: 37px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px 0 0 3px;
  border: 1px solid #d2d2d2;
  border-right: 0;
}
.search-page-form__input:focus {
  outline: 0;
  border-color: #0082dd;
}
.search-page-form__button-submit {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 7px;
}
.search-page-form__button-submit:last-child {
  margin-right: 0;
}
.sort-category-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 593px;
  margin-bottom: 20px;
}
.sort-category__head {
  padding-right: 10px;
  font-size: 14px;
  color: #7b7b7b;
}
.creator-select {
  margin-left: 50px;
  margin-bottom: 20px;
}
.creator-select__button {
  color: #0082dd;
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
}
ul.creator-select-list {
  display: none;
}
.sort-category-list {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.sort-category-item {
  margin-right: 19px;
}
.sort-category-item:last-child {
  margin-right: 0;
}
.sort-category-button {
  display: flex;
  align-content: center;
  background-color: transparent;
  color: #7b7b7b;
  border: 2px solid #7b7b7b;
  border-radius: 3px;
}
.sort-category-button:focus {
  outline: 0;
}
.sort-category-button__icon {
  width: 19px;
  height: 18px;
  margin-left: 14px;
}
.creator-group-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creator-group-label-icon-wrapper {
  width: 20px;
  height: 20px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 10px;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  background-color: #fff;
  transition: 0.25s opacity ease-in;
}
.creator-group-label-icon-wrapper .icon {
  width: 100%;
  height: 100%;
  fill: #0082dd;
  opacity: 0;
}
.creator-group__input-checkbox:checked ~ .creator-group-label .icon {
  opacity: 1;
}
.creator-group-wrapper {
  margin-left: 50px;
  margin-bottom: 20px;
}
.search-page-form-row {
  display: flex;
}
.search-page-form-range-filter-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 593px;
}
.search-page-form-range-filter__head {
  padding-right: 10px;
  font-size: 14px;
  color: #7b7b7b;
}
.search-page-form-range {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.search-page-form-range-list {
  display: flex;
  align-items: center;
}
.search-result-table__head {
  color: #373737;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 20px;
  margin-top: 40px;
}
.search-result-table-item {
  background-color: white;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 16px;
  margin-bottom: 20px;
}
.search-result-table-item:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}
.search-result-item-image-wrapper {
  width: 23.66%;
  padding-right: 36px;
}
.search-result-item-info-line {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  cursor: default;
  display: flex;
}
.search-result-item-info-line__title {
  color: #7b7b7b;
  font-size: 16px;
  margin-right: 6px;
}
.search-result-item-info-line__value {
  color: #373737;
  font-size: 16px;
  font-weight: 500;
}
.search-result-item-info__head {
  line-height: 24px;
  color: #0082dd;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 9px;
  display: block;
}
.search-result-item-info-wrapper {
  width: 76.34%;
  padding-left: 11px;
  padding-top: 9px;
}
.search-result-nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}
.search-result-nav .pagination,
.search-result-nav .content .pagination {
  margin-bottom: 0;
}
.search-result-nav .pagination a,
.search-result-nav .content .pagination a,
.search-result-nav .pagination span,
.search-result-nav .content .pagination span {
  border-bottom: 0;
  color: #0082dd;
  font-size: 14px;
  line-height: 28px;
  border: 1px solid #0082dd;
  border-right: 0;
  outline: none;
}
.search-result-nav .pagination a.active,
.search-result-nav .content .pagination a.active,
.search-result-nav .pagination span.active,
.search-result-nav .content .pagination span.active,
.search-result-nav .pagination a:hover,
.search-result-nav .content .pagination a:hover,
.search-result-nav .pagination span:hover,
.search-result-nav .content .pagination span:hover {
  color: #fff;
}
.search-result-nav .pagination li,
.search-result-nav .content .pagination li {
  width: 30px;
  height: 30px;
  margin-right: 0;
}
.search-result-nav .pagination li:nth-child(2) a,
.search-result-nav .content .pagination li:nth-child(2) a {
  border-radius: 3px 0 0 3px;
}
.search-result-nav .pagination li:nth-last-child(2) a,
.search-result-nav .content .pagination li:nth-last-child(2) a {
  border-radius: 0 3px 3px 0;
  border-right: 1px solid #0082dd;
}
.search-result-nav .pagination a.disabled,
.search-result-nav .content .pagination a.disabled,
.search-result-nav .pagination span.disabled,
.search-result-nav .content .pagination span.disabled {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #0082dd;
}
.search-result-nav .pagination .pagination__prev,
.search-result-nav .content .pagination .pagination__prev,
.search-result-nav .pagination .pagination__next,
.search-result-nav .content .pagination .pagination__next {
  border: none !important;
  width: auto !important;
  height: auto !important;
}
.search-result-nav .pagination .pagination__prev a,
.search-result-nav .pagination .pagination__next a {
  border: none !important;
  width: auto !important;
  height: auto !important;
  margin: 0 15px;
}
.search-result-nav .pagination .pagination__prev a:hover,
.search-result-nav .pagination .pagination__next a:hover {
  background: transparent;
  color: #0082dd;
  text-decoration: underline;
}
.search-result-nav .pagination .pagination__next a {
  margin-right: 0;
}
.search-result-nav .pagination a.disabled,
.search-result-nav .content .pagination a.disabled,
.search-result-nav .pagination span.disabled,
.search-result-nav .content .pagination span.disabled {
  color: #777 !important;
  text-decoration: none !important;
  cursor: not-allowed;
  background-color: transparent;
  border-color: #0082dd;
}
.search-result-nav-show-select {
  border: 1px solid;
  padding: 3px 5px;
  min-width: 210px;
  height: 30px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  color: #373737;
  font-size: 14px;
  line-height: 36px;
}
.search-result-nav-show-count-caption {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  font-size: 14px;
  line-height: 36px;
}
.search-result-nav-show-count-caption__head {
  color: #7b7b7b;
  margin-right: 6px;
}
.search-result-nav-show-count-caption__value {
  color: #373737;
}
.sort-category-list .sort-category-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: transparent;
  color: #7b7b7b;
  border: 2px solid #7b7b7b;
  border-radius: 3px;
  padding: 4px 8px;
}
.sort-category-list .sort-category-button:hover,
.sort-category-list .sort-category-button:focus,
.sort-category-list .sort-category-button.active {
  background-color: #0082dd;
  border: 2px solid #0082dd;
  color: #fff;
}
.search-page-form-range__head {
  margin-right: 15px;
}
.search-page-form-range-item__input {
  width: 170px;
  height: 30px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 30px;
}
.search-page-form-range-item__input:hover,
.search-page-form-range-item__input:focus {
  border: 1px solid #0082dd;
}
.search-page-form-range-item:last-child input {
  margin-right: 0;
}
.search-page-form-range-item__label {
  margin-right: 5px;
}
.search-result-item-link {
  width: 220px;
  height: 185px;
  display: block;
  overflow: hidden;
}
.search-result-item-link img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.button-search-table {
  padding: 6px 13px;
  height: 41px;
  margin-top: 25px;
  min-width: 150px;
  border-width: 2px;
  border-style: solid;
  border-color: #e54d30;
}
.protection-category {
  background: #fff;
  padding: 15px 20px 0;
  margin-bottom: 20px;
}
.protection-category__title {
  color: #0078cc;
  font-size: 24px;
  line-height: 36px;
  text-decoration: underline;
}
.protection-category .spoiler__title {
  display: block;
  border-bottom: none;
  margin-right: 35px;
}
.protection-category .spoiler {
  border-bottom: 2px solid #f4f4f4;
  margin-left: -20px;
  margin-right: -20px;
  padding: 15px 20px;
  padding-left: 40px;
  margin-bottom: 0;
}
.protection-category .spoiler-title-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -35px;
  width: 36px;
  height: 36px;
}
.protection-category .spoiler-title-icon-wrapper .icon {
  width: 37px;
  display: block;
  height: 35px;
  transition: 0.15s -webkit-transform ease;
  transition: 0.15s all ease;
  transition: 0.15s all ease, 0.15s -webkit-transform ease;
  fill: #9d9d9d;
}
.protection-category .spoiler-title-icon-wrapper .icon:hover {
  fill: #0078cc;
}
.protection-category .spoiler-title-icon-wrapper .icon.more {
  display: block;
}
.protection-category .spoiler-title-icon-wrapper .icon.minus {
  display: none;
}
.protection-category .spoiler.active .spoiler-title-icon-wrapper .icon {
  fill: #0078cc;
}
.protection-category .spoiler.active .spoiler-title-icon-wrapper .icon.more {
  display: none;
}
.protection-category .spoiler.active .spoiler-title-icon-wrapper .icon.minus {
  display: block;
}
.protection-category .spoiler.active .spoiler__text {
  margin-bottom: 15px;
  padding-right: 20%;
}
.cart-button_header {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  padding: 4px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-button_header svg {
  width: 26px;
  height: 28px;
  fill: #e54d30;
  margin-left: 10px;
}
.cart-button_header:hover svg {
  fill: #fff;
}
.cart-button_header .ico {
  width: 26px;
  height: 28px;
  margin-left: 10px;
  background: url(../img/sprite_png.png) 0 -118px no-repeat;
}
.cart-button_header:hover .ico {
  background-position: 0 -242px;
}
.protection-sub-item {
  margin-bottom: 15px;
  display: block;
}
.protection-sub-item__link {
  color: #0078cc;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  margin-bottom: 5px;
  display: block;
}
.protection-sub-item__link:hover {
  text-decoration: underline;
}
.protection-sub-item p {
  color: #373737;
  font-size: 16px;
  line-height: 24px;
}
/*.b-product-card*/
.b-product-card .b-product-card__media-price {
  background-color: #fff;
  margin-bottom: 5px;
}
.slider-product-wrapper {
  position: relative;
  padding: 20px 0;
}
.slider-product {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding-left: 65px;
  padding-right: 65px;
}
.slider-product-item__wrap {
  display: flex;
  justify-content: center;
}
.slider-product-item__wrap img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 300px;
}
.slider-product-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.slider-product-prev,
.slider-product-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  border: 0;
  background-color: transparent;
  padding: 0;
  transform: translateY(-50%);
}
.slider-product-prev:focus,
.slider-product-next:focus {
  outline: 0;
}
.slider-product-prev:hover .icon,
.slider-product-next:hover .icon {
  fill: #00384e;
}
.slider-product-prev .icon,
.slider-product-next .icon {
  fill: #0c4733;
  transition: 0.2s fill ease;
}
.slider-product__button-prev {
  left: 0;
}
.slider-product__button-prev .icon {
  transform: rotate(90deg);
}
.slider-product__button-next {
  right: 0;
}
.slider-product__button-next .icon {
  transform: rotate(-90deg);
}
.slider-product__button {
  position: absolute;
  background: url("../img/carousel__arrows.png");
  width: 30px;
  height: 55px;
  cursor: pointer;
  border-width: 0px;
}
.slider-product__button-prev {
  background-position: 0 0;
  left: 14px;
  top: 50%;
  margin-top: -28px;
}
.slider-product__button-prev:hover {
  background-position: 0 -56px;
}
.slider-product__button-next {
  background-position: -31px 0;
  right: 14px;
  top: 50%;
  margin-top: -28px;
}
.slider-product__button-next:hover {
  background-position: -31px -56px;
}
/*
@media (max-width: @screen-md) {
  .slider-product {
    width: 710px;
    height: 447px;
  }
}
@media (max-width: @screen-sm) {
  .slider-product {
    width: auto;
    height: auto;
    max-height: 458px;
  }
}
*/
.slider-product-item .zoom {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;
}
.slider-product-item .zoom .icon {
  fill: #fff;
}
.slider-product-item:hover .zoom {
  opacity: 1;
}
.b-product-card__price-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.f18 {
  font-size: 18px;
}
.gray {
  color: #7b7b7b;
}
.green {
  color: #46c30f;
}
.dark {
  color: #373737;
}
.creator-select__button {
  /* Firefox 19+ */
  /* Firefox 18- */
}
.creator-select__button::-webkit-input-placeholder {
  color: #0082dd;
  text-decoration: underline;
}
.creator-select__button::-moz-placeholder {
  color: #0082dd;
  text-decoration: underline;
}
.creator-select__button:-moz-placeholder {
  color: #0082dd;
  text-decoration: underline;
}
.creator-select__button:-ms-input-placeholder {
  color: #0082dd;
  text-decoration: underline;
}
.select-choose {
  position: relative;
  width: 235px;
  outline: none;
}
.select-choose .creator-group {
  margin-bottom: 10px;
}
.select-choose .creator-group-label-icon-wrapper {
  margin-right: 25px;
}
/* input text styling for select */
.select-choose__title {
  width: 100%;
  text-decoration: none;
  text-overflow: ellipsis;
}
/* link for drop dropdown */
.select-choose__link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 32px;
  outline: none !important;
}
/* hidden dropdown */
.select-choose__list {
  display: none;
  position: absolute;
  top: 34px;
  left: 0;
  z-index: 110;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px 10px 13px;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
}
.select-choose__close {
  display: block;
  height: 30px;
  background-color: #0082dd;
  border-radius: 2px;
  text-align: center;
  color: white;
  font-size: 14px;
  padding: 3px;
  cursor: pointer;
}
.creator-scroll-group {
  height: 130px;
  overflow-y: auto;
  margin-bottom: 13px;
}
.sort-category-button .sort-category-button__icon {
  fill: #7b7b7b;
}
.sort-category-button.active .sort-category-button__icon,
.sort-category-button:focus .sort-category-button__icon,
.sort-category-button:hover .sort-category-button__icon {
  fill: #fff;
}
.sort-category-button .ico {
  width: 14px;
  height: 16px;
  background: url(../img/sort.png) 100% 0 no-repeat;
  margin-left: 8px;
  margin-top: 2px;
}
.sort-category-button.active .ico,
.sort-category-button:focus .ico,
.sort-category-button:hover .ico {
  background: url(../img/sort.png) 0 0 no-repeat;
}
.sort-category-button.increase .ico {
  background: url(../img/sort.png) 100% 0 no-repeat;
}
.sort-category-button.increase:hover .ico {
  background: url(../img/sort.png) 0 0 no-repeat;
}
.sort-category-button.decrease .ico {
  background: url(../img/sort.png) 100% 100% no-repeat;
}
.sort-category-button.decrease:hover .ico {
  background: url(../img/sort.png) 0 100% no-repeat;
}
.cart-list {
  height: 105px;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 1px;
  padding: 0 8px;
}
.cart-list-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  padding: 7px 0px;
}
.cart-list-item-name {
  width: 41.3%;
  color: #373737;
  font-size: 14px;
  line-height: 18px;
}
.cart-list-item-count {
  width: 11.2%;
}
.cart-list-item-count__input {
  width: 40px;
  height: 30px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  padding: 3px;
  color: #373737;
  font-size: 14px;
  line-height: 18px;
}
.cart-list-item-price {
  width: 19.9%;
  color: #373737;
  font-size: 14px;
  line-height: 18px;
}
.cart-list-item-status {
  width: 22.3%;
  color: #bcbcbc;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}
.cart-list-item-control {
  width: 5.3%;
}
.available {
  color: #46c30f;
}
.order {
  color: #e28a00;
}
.notavailable {
  color: #bcbcbc;
}
.cart-list-item-control .cancel {
  width: 30px;
  height: 30px;
  fill: #7b7b7b;
  cursor: pointer;
}
.cart-list-item-control .cancel:hover {
  fill: #0082dd;
}
.cart-list-summ {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 5px 9px;
  padding: 5px 9px 28px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 15px;
}
.cart-list-summ__title {
  color: #373737;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  width: 51.4%;
}
.cart-list-summ__num {
  color: #e54d30;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  width: 48.6%;
}
.cart-modal-title {
  color: #4b4b4b;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 10px;
  padding-left: 7px;
}
.head-cart-modal {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.head-cart-modal .clear-cart-modal {
  color: #373737;
  font-size: 14px;
  line-height: 18px;
}
.head-cart-modal .clear-cart-modal:hover {
  text-decoration: none;
}
.b-product-card__price-column__info {
  padding-right: 35px;
}
.b-product-card__price-column__info p {
  margin-bottom: 10px;
}
.b-product-card__price-column__buttons button {
  margin-right: 10px;
  min-width: 150px;
}
.b-accessories-list__item {
  border: 1px solid #dcdcdc;
  display: flex;
  padding: 18px 20px;
  margin-bottom: 20px;
}
.b-accessories-list__item__img {
  border: 1px solid #dcdcdc;
  margin-right: 43px;
  width: 290px;
}
.b-accessories-list__item__img img {
  width: 288px;
}
.b-accessories-list__item__text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
}
.b-product-card__price-column__text .title {
  color: #0082dd;
  font-size: 18px;
  display: block;
  margin-bottom: 20px;
  text-decoration: none;
}
.b-product-card .attachment {
  margin-bottom: 20px;
}
.b-product-card .attachment .attachment-type {
  margin-bottom: 6px;
}
.b-product-card .attachment-name {
  margin-left: 0;
}
.b-product-card .attachment-type {
  margin-left: 0;
}
.b-product-card table {
  margin: 30px 0;
  font-size: 16px;
}
.b-product-card th,
.b-product-card td {
  padding: 10px 20px;
  border-right: 2px solid #d3d3d3;
  background-color: #f4f4f4;
  width: 50%;
}
.b-product-card th {
  font-weight: bold;
  border-bottom: 2px solid #d3d3d3;
  vertical-align: bottom;
  text-align: left;
  background-color: #fff;
}
.b-product-card th:last-child,
.b-product-card td:last-child {
  border-right: none;
}
.b-product-card tr:nth-child(2n) td {
  background-color: #fff;
}
.mfp-content .mfp-close {
  color: #067dd1;
  font-size: 30px;
  font-weight: 300;
  font-family: "times new roman";
  transition: color 0.3s;
  right: 0;
  top: 32px;
}
.mfp-content .mfp-close:hover {
  color: #000;
}
.mfp-bg {
  background-color: #050909;
  opacity: 0.15;
}
.mfp-figure::after {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.form-wrap-popup {
  position: relative;
  background: #f4f4f4;
  padding: 0px 40px;
  width: auto;
  max-width: 365px;
  margin: 20px auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.form-wrap-popup .site-form-wrapper {
  border: 0px;
}
.form-wrap-popup .site-form__head {
  color: #4b4b4b;
  font-size: 28px;
  margin-bottom: 20px;
}
.form-wrap-popup .site-form__label {
  color: #373737;
  font-size: 14px;
  margin-bottom: 6px;
}
.form-wrap-popup .site-form__input {
  width: 264px;
  height: 30px;
  font-size: 14px;
}
.form-wrap-popup .site-form-element-wrapper {
  margin-bottom: 7px;
}
.form-wrap-popup .page-button {
  margin-top: 13px;
  margin-bottom: 40px;
}
.form-wrap-popup .mfp-close {
  color: #067dd1;
  font-size: 30px;
  font-weight: 300;
  font-family: "times new roman";
  transition: color 0.3s;
  right: -6px;
  top: -5px;
}
.form-wrap-popup .mfp-close:hover {
  color: #000;
}
.busket {
  width: 28px;
  height: 32px;
  background: url(../img/busket.png) 0 100% no-repeat;
  color: #e54d30;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  float: right;
  margin-top: 30px;
  margin-right: 20px;
}
.horiz-menu {
  max-width: 930px;
}
.horiz-menu__item {
  padding-right: 29px;
}
@media (max-width: 768px) {
  /* 768px по умолчанию */
  .content .tab li,
  .tab li {
    max-width: 350px;
    flex-basis: 100%;
    width: auto;
    margin-right: 0;
    border: 1px solid #adadad;
    border-bottom: 0;
    flex-grow: 1;
    flex-basis: auto;
  }
}
.cart-content .form__trxtareawrp {
  margin-right: 0;
  margin-top: 0;
}
.cart-content .file-uploader {
  flex: 0 1 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 5px;
}
.cart-content .file-uploader #file-input {
  display: none;
}
.cart-content .file-uploader label[for="file-input"] {
  padding: 1em 1.5em;
  flex: 1 1 auto;
  cursor: pointer;
  text-align: center;
  color: #FFF;
  background-color: #1894AC;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.cart-content .file-uploader .image-preview {
  margin: 0 0 1rem;
  min-height: 250px;
  flex: 1 0 75%;
  background-color: #c1c1c1;
}
.cart-content .form__field-wrp {
  margin-top: 6px;
  width: 100%;
}
.cart-content .form__field-wrp > label {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
.cart-content .form__label {
  display: block;
  font-size: 14px;
  width: 30%;
}
.cart-content .form__text {
  width: 264px;
  height: 30px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  margin-top: 0;
}
.cart-content .desc-required {
  font-size: 14px;
  display: flex;
  padding: 2px 20px;
  line-height: 23px;
  display: inline-block;
}
.cart-content .desc-required sup {
  vertical-align: baseline;
  color: red;
  font-size: 18px;
  margin-bottom: -49px;
  position: relative;
  top: -3px;
  left: -3px;
}
.cart-content .form__error {
  font-size: 13px;
  margin-top: 0px;
  padding-left: 26px;
}
.cart-content .creator-group-label__text {
  font-size: 14px;
}
.cart-content .creator-group-label {
  display: inline-flex;
}
.cart-content .form__textarea {
  width: 70%;
  padding: 8px 10px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  background-color: #fff;
  display: block;
  box-sizing: border-box;
  margin-top: 3px;
  height: 72px;
}
.form__trxtareawrp label {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
}
.form__trxtareawrp .half {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.form__trxtareawrp .form__error {
  width: 100%;
  text-align: right;
  height: 22px;
}
.form__trxtareawrp .creator-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.cart-modal-footer {
  font-size: 14px;
  margin-top: 40px;
}
.cart-modal-footer p {
  color: #373737;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}
.delivery-form-input input.form__text {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
}
.delivery-row-cart {
  padding-right: 70px;
}
.delivery-row-cart input[type="text"] {
  margin-bottom: 20px;
}
.delivery-row-cart__title {
  color: #373737;
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px;
}
.requisites-legal-input.form__text {
  display: none;
  width: 100%;
  height: 40px;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
}
.requisites-legal-control {
  display: block;
  color: #0082dd;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}
.requisites-legal-control:hover {
  text-decoration: underline;
}
.popup-wrp {
  overflow-y: scroll !important;
}
/* Radio label */
@keyframes radio-select {
  0% {
    transform: scale(0, 0);
  }
  65% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
label.radio-label {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  color: #373737;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 5px;
}
label.radio-label input {
  height: 1px;
  width: 1px;
  opacity: 0;
}
label.radio-label .outside {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #d2d2d2;
}
label.radio-label .inside {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  position: absolute;
  margin-left: -5px;
  margin-top: -5px;
  transform: scale(0, 0);
  background-color: #0082dd;
}
.no-transforms label.radio-label .inside {
  left: auto;
  top: auto;
  width: 0;
  height: 0;
}
label.radio-label input:checked + .outside .inside {
  animation: radio-select 0.3s linear;
  transform: scale(1, 1);
}
.no-transforms label.radio-label input:checked + .outside .inside {
  width: 10px;
  height: 10px;
}
/* Radio label END */
.protection-category .spoiler .spoiler__title:hover {
  text-decoration: underline;
}
.protection-category__title:hover {
  text-decoration: none;
}
.spoiler-title-icon-wrapper {
  position: relative;
}
.spoiler .minus {
  background: url(../img/sprite_png.png) 0 0 no-repeat;
}
.spoiler .more {
  background: url(../img/sprite_png.png) 0 -36px no-repeat;
}
.spoiler .minus {
  display: block;
}
.spoiler .more {
  display: none;
}
.spoiler.active .minus {
  display: none;
}
.spoiler.active .more {
  display: block;
}
.icon-wrap {
  display: block;
  width: 35px;
  height: 35px;
  background-color: red;
}
.protection-category .spoiler-title-icon-wrapper .icon {
  display: none;
}
.creator-select__button {
  line-height: 16px;
}
/**
* альтернативное отображение спойлера на странице protection
*/
.protection-info-block-subtitle-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.protection-info-block .icon-wrap {
  background: url(../img/sprite_png.png) 0 -36px no-repeat !important;
}
.protection-info-block .spoiler-title-icon-wrapper {
  right: 0;
}
.protection-info-block .icon-wrap {
  display: none;
}
.protection-info-block.active .protection-info-button-close {
  display: block;
}
.protection-info-block:not(.active) .protection-info-button-open {
  display: block;
}
.protection-info-block .protection-info-button-open {
  background-position: 0 0 !important;
}
.protection-info-block.active .icon {
  fill: #0078cc;
}
.protection-info-block .icon {
  width: 37px;
  display: block;
  height: 35px;
  transition: 0.15s -webkit-transform ease;
  transition: 0.15s all ease;
  transition: 0.15s all ease, 0.15s -webkit-transform ease;
  fill: #9d9d9d;
}
.protection-info-block.active .spoiler__text {
  display: block;
}
.protection-info-block {
  border-bottom: 2px solid #f4f4f4;
  margin-left: -20px;
  margin-right: -20px;
  padding: 15px 20px;
  padding-left: 40px;
  margin-bottom: 0;
}
.protection-info-block__subtitle {
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  color: #0082dd;
  border-bottom: none;
  margin-right: 35px;
  text-decoration: none;
}
.site-form label.error,
#error-form {
  left: 10px;
  font-size: 13px;
  color: #d04035;
}
.b-tabs-wrap .tab-pane.fade.in .content.empty {
  min-height: 350px;
}
.b-product-card__price-column {
  padding-top: 15px;
  padding-bottom: 15px;
}
.delivery-toggle {
  display: none;
}
.delivery-toggle.active {
  display: block;
}
/**
* файл для стилей для разных устройств
*/
